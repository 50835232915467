<template>
  <div>
    <v-card class="mx-auto">
      <v-card-title
        >Request Pumping Quote <v-spacer></v-spacer
        ><v-btn @click="$emit('close')" icon
          ><v-icon>mdi-close</v-icon></v-btn
        ></v-card-title
      >
      <v-card-text class="mt-n2">
        <div>
          Peeps' helpers are doing some research to provide you with contact
          information to request pumping quotes from local companies. Please
          check back soon. If you would like to be notified when that is done,
          just let Peeps know by clicking on this button
          <div class="mt-3 text-center">
            <let-peeps-know
              target="_blank"
              depressed
              color="primary"
              subject="the local pumping information is available"
              body="Please let me know when the information about local companies that will give me a quote on my septic pumping is available.   Thank you."
              icon="mdi-email"
            ></let-peeps-know>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  components: {
    LetPeepsKnow: () => import('@/components/LetPeepsKnow')
  }
}
</script>

<style></style>
